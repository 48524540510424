(function($) {
    $("#btn1").click(function(event){
        event.preventDefault();
        $("#panel1").show();
        $("#panel2").hide();
        $("#panel3").hide();
        $("#panel4").hide();
    });

    $("#btn2").click(function(event){
        event.preventDefault();
        $("#panel2").show();
        $("#panel1").hide();
        $("#panel3").hide();
        $("#panel4").hide();
    });

    $("#btn3").click(function(event){
        event.preventDefault();
        $("#panel3").show();
        $("#panel1").hide();
        $("#panel2").hide();
        $("#panel4").hide();
    });

    $("#btn4").click(function(event){
        event.preventDefault();
        $("#panel4").show();
        $("#panel1").hide();
        $("#panel2").hide();
        $("#panel3").hide();
    });
  })(jQuery);
